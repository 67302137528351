<template>
  <section id="not-ordered-products">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <v-select v-model="selectedSupplier" placeholder="Select supplier" label="title" :options="allSuppliers"
            @input="changeSelectedSupplier" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showNotOrderedProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Not Ordered Products
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-not-ordered-products" />
          </h4>
          <b-popover target="popover-not-ordered-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="notOrderedProductsTable.currentPage"
          :items="notOrderedProductsTable.items" :fields="notOrderedProductsTable.fields"
          :sort-by.sync="notOrderedProductsTable.sortBy" :sort-desc.sync="notOrderedProductsTable.sortDesc"
          :sort-direction="notOrderedProductsTable.sortDirection" :filter="notOrderedProductsTable.filter"
          :filter-included-fields="notOrderedProductsTable.filterOn" @sort-changed="sortChanged" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="notOrderedProductsTable.currentPage" :total-rows="notOrderedProductsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleNotOrderedProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BPopover,
  BCard,
  BOverlay,
} from 'bootstrap-vue';
import axios from 'axios';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      showNotOrderedProducts: true,
      allSuppliers: [],
      selectedSupplier: '',
      queryParams: {},
      notOrderedProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'stock_qty',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'supplier_sku', label: 'supplier sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'supplier', label: 'supplier', sortable: true },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'supplier_stock_qty', label: 'supplier stock qty', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getAllSuppliers();
      await this.getNotOrderedProducts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllSuppliers() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-suppliers/`, {});
        this.allSuppliers = response.data.results.map(item => {
          return { title: item.title, value: item.value };
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getNotOrderedProducts() {
      this.showNotOrderedProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/not-ordered-products/`, this.queryParams);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.notOrderedProductsTable.items = results;
          this.notOrderedProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.notOrderedProductsTable.items = [];
          this.notOrderedProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showNotOrderedProducts = false;
      }
    },
    async changeSelectedSupplier() {
      this.queryParams.page = 1;
      this.queryParams.supplier_id = this.selectedSupplier.value;
      await this.getNotOrderedProducts();
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParams.ordering = `-${value.sortBy}`;
      } else {
        this.queryParams.ordering = value.sortBy;
      }
      await this.getNotOrderedProducts();
    },
    async handleNotOrderedProductsTablePageChange(value) {
      this.queryParams.page = value;
      await this.getNotOrderedProducts();
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>